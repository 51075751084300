import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SubscriptionSolver } from '@trade4sure/t4s-lib';
import { filter } from 'rxjs';
import { HeaderConfig, HeaderService } from 'src/app/modules/site/services/header.service';
import { ButtonItemModel } from 'src/app/_models/button-item.model';
import { ExportService } from 'src/app/modules/site/services/export.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends SubscriptionSolver {
  public header: HeaderConfig = {
    title: '',
    filter: false,
    filters: [],
    breadCrumbs: [],
    tabs: [],
    activeTab: '',
    button: null,
    hasExport: false,
  };

  public isVisible = true;

  constructor(private headerService: HeaderService, private router: Router, public exportService: ExportService) {
    super();
    this.subscriptions.push(
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => this.headerService.resetHeader()),

      this.headerService.header.subscribe((res) => (this.header = res))
    );
  }

  onButtonClick(button: ButtonItemModel) {
    this.headerService.buttonClick.next(button);
  }

  public onTabChange(tab: string) {
    this.headerService.activatedTab.next(tab);
  }

  public onExportClicked() {
    this.exportService.executeExport();
  }
}
