import { Injectable } from '@angular/core';
import { ApiClientService, ControllerBase } from '@trade4sure/t4s-lib';
import { Observable } from 'rxjs';
import { FilterValues } from 'src/app/_models/filter-item.model';
export interface ExportRequest {
  filterValues: FilterValues;
}

@Injectable()
export abstract class QueryControllerBase extends ControllerBase {
  constructor(protected override apiClient: ApiClientService) {
    super(apiClient);
  }

  public export(exportRequest: ExportRequest): Observable<string | undefined> {
    return this.postWithoutPipe<ExportRequest>(['export'], exportRequest);
  }
}
