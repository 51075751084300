import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, filter, Subject } from 'rxjs';
import { Breadcrumb } from 'src/app/components/graphic/breadcrumbs/breadcrumbs.component';
import { ButtonItemModel } from 'src/app/_models/button-item.model';
import { FilterItemModel } from 'src/app/_models/filter-item.model';
import { CurrentExportType, ExportService } from './export.service';

export interface HeaderConfig {
  title: string;
  tabs: string[];
  activeTab: string;
  breadCrumbs: Breadcrumb[];
  filter: boolean;
  filters: FilterItemModel[];
  button: ButtonItemModel | null;
  hasExport: boolean;
}

@Injectable()
export class HeaderService {
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private router: Router, private exportService: ExportService) {
    this.activateRouterEvents();
  }

  activateRouterEvents() {
    this.router.events.pipe(filter((event) => event instanceof NavigationStart || event instanceof NavigationEnd)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loading$.next(true);
      } else if (event instanceof NavigationEnd) {
        this.loading$.next(false);
      }
    });
  }

  public header = new BehaviorSubject<HeaderConfig>({
    title: '',
    filter: false,
    filters: [],
    breadCrumbs: [],
    tabs: [],
    activeTab: '',
    button: null,
    hasExport: false,
  });

  public activatedTab = new Subject<string>();
  public buttonClick = new Subject<ButtonItemModel>();

  public setHeader(config: HeaderConfig) {
    this.header.next(config);
  }

  public resetHeader() {
    this.header.next({
      title: '',
      filter: false,
      filters: [],
      breadCrumbs: [],
      tabs: [],
      activeTab: '',
      button: null,
      hasExport: false,
    });
    this.exportService.setExportType(CurrentExportType.NONE);
  }
}
