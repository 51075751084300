import { Injectable } from '@angular/core';
import { ModelBase } from '@trade4sure/t4s-lib';
import { Observable } from 'rxjs';

import {
  AssessmentRecordDetailResponse,
  AssessmentStatistics,
  AssessmentsListRequest,
  AssessmentsListResponse,
} from '../../models/assessments/assessments.model';
import { QueryControllerBase } from '../query-controller-base.service';

@Injectable({
  providedIn: 'root',
})
export class AssessmentsControllerService extends QueryControllerBase {
  protected resourceName = 'DebtorTest';

  public getAssessmentsList(assessmentsListRequest: AssessmentsListRequest): Observable<AssessmentsListResponse | undefined> {
    return this.post<AssessmentsListRequest, AssessmentsListResponse>(['list'], assessmentsListRequest);
  }

  public getAssessmentDetailsById(id: string): Observable<AssessmentRecordDetailResponse | undefined> {
    return this.get<AssessmentRecordDetailResponse>([id]);
  }

  public getAssessmentStatisticsByUserId(id: string): Observable<AssessmentStatistics | undefined> {
    return this.get<AssessmentStatistics>([id, 'statistics']);
  }

  public activateAssessment(id: string): Observable<ModelBase | undefined> {
    return this.put([id, 'subscribe']);
  }

  public deactivateAssessment(id: string): Observable<ModelBase | undefined> {
    return this.put([id, 'unsubscribe']);
  }
}
