import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EnvironmentDetailsControllerService } from '@trade4sure/t4s-lib';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public remember = new FormControl(false);
  constructor(private environmentDetails: EnvironmentDetailsControllerService) {}

  ngOnInit(): void {
    this.environmentDetails.fetchEnvironment();
  }
}
