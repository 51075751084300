import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubscriptionProductListRequest, SubscriptionProductListResponse, SubscriptionProductRecord, UpdateSubscriptionProduct } from '../../models/product/product.model';
import { ApiClientService, ControllerBase, ModelBase } from '@trade4sure/t4s-lib';

@Injectable()
export class ProductsControllerService extends ControllerBase {
  protected resourceName = 'SubscriptionProduct';

  constructor(protected override apiClient: ApiClientService) {
    super(apiClient);
  }
  
  public getSubscriptionList(subscriptionProductListRequest: SubscriptionProductListRequest) : Observable<SubscriptionProductListResponse | undefined> {
    return this.post<SubscriptionProductListRequest, SubscriptionProductListResponse>(['list'], subscriptionProductListRequest);
  }

  public getSubscriptionProductById(id: string): Observable<SubscriptionProductRecord | undefined> {
    return this.get<SubscriptionProductRecord>(id);
  }

  public activateSubscriptionProductById(id: string): Observable<ModelBase | undefined> {
    return this.put([id, 'activate']);
  }

  public deleteSubscriptionProductById(id: string): Observable<ModelBase | undefined> {
    return this.delete<ModelBase>([id]);
  }

  public updateSubscriptionProductById(id: string, body: UpdateSubscriptionProduct): Observable<ModelBase | undefined> {
    return this.put<UpdateSubscriptionProduct, ModelBase>([id], body);
  }
}