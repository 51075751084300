import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InsuranceRecordDetailResponse, InsurancesListRequest, InsurancesListResponse, InsurancesUserListRequest } from '../../models/insurances/insurances.model';
import { QueryControllerBase } from '../query-controller-base.service';

@Injectable({
  providedIn: 'root',
})
export class InsurancesControllerService extends QueryControllerBase {
  protected resourceName = 'Insurance';

  public getInsurancesList(insurancesListRequest: InsurancesListRequest): Observable<InsurancesListResponse | undefined> {
    return this.post<InsurancesListRequest, InsurancesListResponse>(['list'], insurancesListRequest);
  }

  public getInsuranceDetailsById(id: string): Observable<InsuranceRecordDetailResponse | undefined> {
    return this.get<InsuranceRecordDetailResponse>([id]);
  }

  public getInsurancesByUser(insurancesUserListRequest: InsurancesUserListRequest): Observable<InsurancesListResponse | undefined> {
    return this.post<InsurancesUserListRequest, InsurancesListResponse>(['list'], insurancesUserListRequest);
  }
}
