<ng-template #tooltipContent class="tooltip-content">
    <div class="d-flex justify-content-between">
        <span class="tooltip-title">{{ title }}</span>
        <span class="material-symbols-outlined tooltip-close"
            (click)="tooltip.close()">
            close
        </span>
    </div>
    <span [innerHTML]="description"></span>
</ng-template>
<span class="material-symbols-outlined small-icon ms-1 {{class}}"
    [style]="style"
    [placement]="position"
    [ngbTooltip]="tooltipContent"
    [autoClose]="false"
    triggers="hover"
    #tooltip="ngbTooltip"
    (click)="tooltip.open()">
    info
</span>
