import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ReportDamageRecordDetailResponse,
  ReportDamagerUserListRequest,
  ReportDamagesListRequest,
  ReportDamagesListResponse,
} from '../../models/report-damages/report-damages.model';
import { QueryControllerBase } from '../query-controller-base.service';

@Injectable({
  providedIn: 'root',
})
export class ReportDamagesControllerService extends QueryControllerBase {
  protected resourceName = 'InsuranceClaim';

  public getReportDamagesList(reportDamagesListRequest: ReportDamagesListRequest): Observable<ReportDamagesListResponse | undefined> {
    return this.post<ReportDamagesListRequest, ReportDamagesListResponse>(['list'], reportDamagesListRequest);
  }

  public getReportDamageDetailsById(id: string): Observable<ReportDamageRecordDetailResponse | undefined> {
    return this.get<ReportDamageRecordDetailResponse>([id]);
  }

  public getReportDamagesByUser(
    reportDamagerUserListRequest: ReportDamagerUserListRequest,
  ): Observable<ReportDamagesListResponse | undefined> {
    return this.post<ReportDamagerUserListRequest, ReportDamagesListResponse>(['list'], reportDamagerUserListRequest);
  }
}
