import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Trade4SureCoreComponentsModule } from '@trade4sure/t4s-lib';
import { GraphicComponentsModule } from '../../graphic/graphic-components.module';
import { InputComponentsModule } from '../../input/input-components.module';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { HeaderComponent } from './header/header.component';

@NgModule({
  declarations: [
    SideMenuComponent,
    HeaderComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    InputComponentsModule,
    GraphicComponentsModule
  ],
  exports: [
    SideMenuComponent,
    Trade4SureCoreComponentsModule,
    HeaderComponent
  ],
})
export class CoreSiteComponentsModule {}
