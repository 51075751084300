import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { KeyValue } from 'src/app/_models/key-value.model';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  filters$: Subject<KeyValue[]> = new Subject<KeyValue[]>();
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
}
