import { Injectable } from '@angular/core';
import { ApiClientService, ControllerBase, ModelBase } from '@trade4sure/t4s-lib';
import {
  EnvironmentApiKeysRequest,
  EnvironmentApiKeysResponse,
  EnvironmentDetailsResponse,
  EnvironmentUpdateRequest,
  EnvironmentVisibleResponse,
} from '../../models/environment-details/environment-details.model';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, ReplaySubject } from 'rxjs';

@Injectable()
export class EnvironmentControllerService extends ControllerBase {
  protected resourceName = 'Environment';

  private _visibleEnvironments = new ReplaySubject<EnvironmentVisibleResponse | undefined>(1);
  private _currentEnvironment = new ReplaySubject<EnvironmentDetailsResponse | undefined>(1);

  public visibleEnvironments = this._visibleEnvironments.asObservable();
  public currentEnvironment = this._currentEnvironment.asObservable();

  constructor(protected override apiClient: ApiClientService, private http: HttpClient) {
    super(apiClient);
  }

  public getEnvironmentDetails(): Observable<EnvironmentDetailsResponse | undefined> {
    return this.get<EnvironmentDetailsResponse>();
  }

  public fetchEnvironmentDetails(): void {
    this.getEnvironmentDetails().subscribe((response) => {
      if (!response) {
        return;
      }
      this._currentEnvironment.next(response);
    });
  }

  public fetchVisibleEnvironments(): void {
    this.get<EnvironmentVisibleResponse>(['visible']).subscribe((response) => {
      if (!response) {
        return;
      }
      this._visibleEnvironments.next(response);
    });
  }

  public getEnvironments(): Observable<EnvironmentVisibleResponse | undefined> {
    return this.get<EnvironmentVisibleResponse>(['visible']);
  }

  public getApiKeys(): Observable<EnvironmentApiKeysResponse | undefined> {
    return this.get<EnvironmentApiKeysResponse>(['apiKeys']);
  }

  getFileSize(url: string): Observable<number> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream', // Optional, depending on the server's requirements
    });

    return this.http.head(url, { headers, observe: 'response' }).pipe(
      map((response) => {
        const contentLength = response.headers.get('Content-Length');
        return contentLength ? +contentLength : 0;
      }),
    );
  }

  public updateApiKeys(body: EnvironmentApiKeysRequest): Observable<ModelBase | undefined> {
    return this.put<EnvironmentApiKeysRequest, EnvironmentApiKeysResponse>(['apiKeys'], body);
  }

  public updateEnvironment(body: EnvironmentUpdateRequest): Observable<ModelBase | undefined> {
    return this.put<EnvironmentUpdateRequest, ModelBase>([], body);
  }
}
