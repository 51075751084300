import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { UsersControllerService } from './services/users/users-controller.service';
import { CountriesControllerService } from './services/countries/countries-controller.service';
import { ProductsControllerService } from './services/products/products-controller.service';
import { StaticValuesControllerService } from './services/static-values/static-values-controller.service';
import { EnvironmentControllerService } from './services/environment/environment-controller.service';
import { FileControllerService } from './services/file/file-controller.service';
import { EnvironmentOpinionControllerService } from './services/environment-opinion/environment-opinion-controller.service';

@NgModule({
  providers: [
    UsersControllerService,
    CountriesControllerService,
    ProductsControllerService,
    StaticValuesControllerService,
    EnvironmentControllerService,
    FileControllerService,
    EnvironmentOpinionControllerService
  ],
  imports: [
    HttpClientModule
  ],
})
export class ApiClientModule {}
