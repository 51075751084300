<app-loader *ngIf="isLoading"></app-loader>

<ng-container *ngIf="data.length && !isLoading">
  <table class="table">
    <thead class="table__header">
      <tr>
        <ng-container
          *ngTemplateOutlet="
            headers || defaultHeaderTemplate;
            context: { $implicit: data }
          "
        ></ng-container>
      </tr>
    </thead>
    <tbody class="table__body">
      <tr *ngFor="let row of data">
        <ng-container
          *ngTemplateOutlet="
            rows || defaultRowTemplate;
            context: { $implicit: row }
          "
        ></ng-container>
      </tr>
    </tbody>
  </table>
  <div class="table__footer">
    <span>{{ paginationConfig.totalSize + ' total item' + (paginationConfig.totalSize > 1 ? 's' : '') }}</span>
    <ngb-pagination 
      [(page)]="page"
      [pageSize]="pageSize" 
      [collectionSize]="paginationConfig.totalSize" 
      [maxSize]="paginator"
      [ellipses]="true"
      (pageChange)="onPagination()"
    ></ngb-pagination>
    <select 
      class="form-select" 
      style="width: auto" 
      name="pageSize" 
      [(ngModel)]="pageSize" 
      (ngModelChange)="onPagination()"
    >
      <option [ngValue]="25">25 / page</option>
      <option [ngValue]="50">50 / page</option>
      <option [ngValue]="100">100 / page</option>
    </select>
  </div>
</ng-container>

<ng-template #defaultHeaderTemplate let-data>
  <th *ngFor="let header of data[0] | keyvalue">{{ header.key }}</th>
</ng-template>

<ng-template #defaultRowTemplate let-row>
  <td *ngFor="let row of row | keyvalue">{{ row.value }}</td>
</ng-template>

<t4s-empty-overview
  *ngIf="!data.length"
  icon="search_off"
  title="Geen resultaten gevonden"
  [description]="descriptionEmptyOverview"
></t4s-empty-overview>
