import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { HeaderService } from 'src/app/modules/site/services/header.service';
import { ConfigData } from 'src/app/_data/config.data';
import { FilterService } from '../filter/services/filter.service';

export interface Pagination {
  page: number;
  pageSize: number;
}

export interface PaginationConfig {
  totalPages: number;
  totalSize: number;
}

@Component({
  selector: 'app-datagrid',
  templateUrl: './datagrid.component.html',
  styleUrls: ['./datagrid.component.scss'],
})
export class DatagridComponent {
  @Input() data!: any[];
  @Input() paginationConfig!: PaginationConfig;
  @Input() descriptionEmptyOverview!: string;

  @Output() paginationChange = new EventEmitter<Pagination>();

  @ContentChild('headers') headers: TemplateRef<any> | undefined;
  @ContentChild('rows') rows: TemplateRef<any> | undefined;

  public paginator: number = ConfigData.pagination.max;
  public page = ConfigData.pagination.initPage;
  public pageSize = ConfigData.pagination.pageSize;
  public isLoading = false;

  constructor(private headerService: HeaderService, private filterService: FilterService) {
    this.headerService.loading$.subscribe((value) => {
      this.isLoading = value;
    });

    this.filterService.filters$.subscribe((filters) => {
      const filtersExist = Object.keys(filters).length > 0;
      this.page = filtersExist ? 1 : this.page;
    });
  }

  public onPagination() {
    if (this.page > Math.ceil(this.paginationConfig.totalSize / this.pageSize)) {
      this.page = Math.ceil(this.paginationConfig.totalSize / this.pageSize);
    }

    this.filterService.loading$.next(true);
    this.paginationChange.emit({
      page: this.page,
      pageSize: this.pageSize,
    });
  }
}
