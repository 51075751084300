import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-file-upload-display',
  templateUrl: './file-upload-display.component.html',
  styleUrls: ['./file-upload-display.component.scss'],
})
export class FileUploadDisplayComponent {
  @Input() amountOfBytes = -1;
  @Input() fileName = '';
  @Input() fileUrl = '';

  public getFileNameText() {
    return this.fileName !== '' ? this.fileName : 'No file found';
  }

  public openFileInNewTab() {
    if (!this.hasFileUrl()) return;
    window.open(this.fileUrl, '_blank');
  }

  public hasFileUrl(): boolean {
    return this.fileUrl !== '';
  }
  
  public formatBytesToText(bytes: number): string {
    if (this.fileName == '' || this.amountOfBytes == -1) return '';

    const units = ['b', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];
    let unitIndex = 0;

    while (bytes >= 1024 && unitIndex < units.length - 1) {
      bytes /= 1024;
      unitIndex++;
    }

    return `${unitIndex <= 0 ? bytes : bytes.toFixed(2)}${units[unitIndex]}`;
  }
}
