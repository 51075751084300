import { Injectable } from '@angular/core';
import { ModelBase } from '@trade4sure/t4s-lib';
import { Observable } from 'rxjs';
import { DeleteUserRequest, UserRecordDetailResponse, UsersListResponse, UsersListRequest, SaveOperatorRequest, UpdateUserKycRequest, UpdateUserRequest } from '../../models/users/users.model';
import { QueryControllerBase } from '../query-controller-base.service';

@Injectable()
export class UsersControllerService extends QueryControllerBase {
  protected resourceName = 'CustomerUser';

  public getUsersList(usersListRequest: UsersListRequest): Observable<UsersListResponse | undefined> {
    return this.post<UsersListRequest, UsersListResponse>(['list'], usersListRequest);
  }

  public getUserDetailsById(id: string): Observable<UserRecordDetailResponse | undefined> {
    return this.get<UserRecordDetailResponse>([id]);
  }

  public saveOperator(body: SaveOperatorRequest): Observable<ModelBase | undefined> {
    return this.apiClient.postWithoutErrorHandling<SaveOperatorRequest, ModelBase>(this.resourceName, ['save'], body);
  }

  public deleteUserById(id: string, body: DeleteUserRequest): Observable<ModelBase | undefined> {
    return this.apiClient.postWithoutErrorHandling<DeleteUserRequest, ModelBase>(this.resourceName, [id, 'delete'], body);
  }

  public resetUserPasswordById(id: string): Observable<ModelBase | undefined> {
    return this.post([id, 'password', 'reset']);
  }

  public activateUserById(id: string): Observable<ModelBase | undefined> {
    return this.apiClient.putWithoutErrorHandling(this.resourceName, [id, 'activate']);
  }

  public updateKycById(id: string, body: UpdateUserKycRequest): Observable<ModelBase | undefined> {
    return this.put<UpdateUserKycRequest, ModelBase>([id, 'kyc'], body);
  }

  public updateUserById(id: string, body: UpdateUserRequest): Observable<ModelBase | undefined> {
    return this.put<UpdateUserRequest, ModelBase>([id], body);
  }

  public resetUserRejectedQuotationCountById(id: string): Observable<ModelBase | undefined> {
    return this.put<void, ModelBase>([id, 'rejected-insurance-quotations/reset']);
  }
}
