import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClientService, ControllerBase, ModelBase } from '@trade4sure/t4s-lib';
import {
  ActivateEnvironmentOpinionsRequest,
  EnvironmentOpinionsResponse,
  UpdateEnvironmentOpinionsRequest,
} from '../../models/environment-opinion/environment-opinion.model';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentOpinionControllerService extends ControllerBase {
  protected resourceName = 'EnvironmentOpinion';

  constructor(protected override apiClient: ApiClientService, private http: HttpClient) {
    super(apiClient);
  }

  public getEnvironmentOpinions(environmentId: string) {
    return this.get<EnvironmentOpinionsResponse>(environmentId);
  }

  public updateEnvironmentOpinions(environmentId: string, body: UpdateEnvironmentOpinionsRequest) {
    return this.put<UpdateEnvironmentOpinionsRequest, ModelBase>([environmentId], body);
  }

  public activateEnvironmentOpinions(environmentId: string) {
    return this.put<ActivateEnvironmentOpinionsRequest, ModelBase>([environmentId, 'activate'], { delete: false });
  }

  public disableEnvironmentOpinions(environmentId: string) {
    return this.put<ActivateEnvironmentOpinionsRequest, ModelBase>([environmentId, 'activate'], { delete: true });
  }
}
