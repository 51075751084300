import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Trade4SureInputComponentsModule } from '@trade4sure/t4s-lib';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { GraphicComponentsModule } from '../graphic/graphic-components.module';
import { MultiDropdownComponent } from './multi-dropdown/multi-dropdown.component';
import { HeaderService } from 'src/app/modules/site/services/header.service';

@NgModule({
  declarations: [MultiDropdownComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, GraphicComponentsModule, Trade4SureInputComponentsModule],
  exports: [MultiDropdownComponent, Trade4SureInputComponentsModule],
  providers: [HeaderService],
})
export class InputComponentsModule {}
