<div class="filter">
  <div class="filter__inner">
    <div class="filter__row">
      <ng-container *ngFor="let item of filters; let i = index">
        <ng-container [ngSwitch]="item.type">
          <t4s-search-input
            *ngIf="(showAllFilters || i < initiallyVisibleFilters) && item.type === componentTypeValues.Search"
            [label]="item.label"
            [placeholder]="item.placeholder"
            (keyup.enter)="hndSearchClick()"
          >
          </t4s-search-input>
          <!-- <t4s-search-input *ngSwitchCase="componentTypeValues.Search"
           [label]="item.placeholder" 
           [placeholder]="item.placeholder" 
           [control]="controls[i]">
          </t4s-search-input> -->

          <t4s-dropdown
            *ngIf="(showAllFilters || i < initiallyVisibleFilters) && item.type === componentTypeValues.Select"
            [label]="item.label"
            [control]="controls[i].control"
            [items]="item.options"
            (keyup.enter)="hndSearchClick()"
          >
          </t4s-dropdown>

          <div
            class="container-picker"
            *ngIf="(showAllFilters || i < initiallyVisibleFilters) && item.type === componentTypeValues.DatePicker"
          >
            <span
              *ngIf="controls[i].control?.value !== null"
              class="material-icons cancel-icon btn-reset"
              (click)="hndResetDatePickerValue(i)"
              >cancel</span
            >

            <t4s-datepicker-input [label]="item.label" [control]="controls[i].control" (keyup.enter)="hndSearchClick()">
            </t4s-datepicker-input>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="filter-button-wrapper gap-3">
    <button class="filter-button btn btn-outline-primary" *ngIf="filters.length >= 4" (click)="hndHideAllFilterClick()">
      {{ showAllFilters ? 'Filters verbergen' : 'Bekijk alle filters' }}
    </button>
    <button class="filter-button btn btn-outline-primary" (click)="hndClearClick()">
      <span class="filter-button__icon material-symbols-outlined">delete</span>
      <span class="filter-button__text">Clear</span>
    </button>
    <button class="filter-button btn btn-outline-primary" (click)="hndSearchClick()">
      <span class="filter-button__icon material-symbols-outlined">filter_alt</span>
      <span class="filter-button__text">Filter</span>
    </button>
  </div>
</div>
