import { Injectable } from '@angular/core';
import { ToastService, ToastType } from '@trade4sure/t4s-lib';
import { DebtorsControllerService } from '../../api-client/services/debtors/debtors-controller.service';
import { Observer, Subject } from 'rxjs';
import { AssessmentsControllerService } from '../../api-client/services/assessments/assessments-controller.service';
import { CountriesControllerService } from '../../api-client/services/countries/countries-controller.service';
import { ReportDamagesControllerService } from '../../api-client/services/report-damages/report-damages-controller.service';
import { InsurancesControllerService } from '../../api-client/services/insurances/insurances-controller.service';
import { UsersControllerService } from '../../api-client/services/users/users-controller.service';
import { ResellersControllerService } from '../../api-client/services/users/resellers/resellers-controller.service';
import { OperatorsControllerService } from '../../api-client/services/users/operators/operators-controller.service';
import { PurchasesControllerService } from '../../api-client/services/purchases/purchases.service';
import { FilterValues } from 'src/app/_models/filter-item.model';
import { ExportRequest } from '../../api-client/services/query-controller-base.service';

export enum CurrentExportType {
  NONE,
  MEMBERS,
  OPERATORS,
  RESELLERS,
  DEBTOR,
  ASSESSMENT,
  SINGLE_DEBTOR_TEST_PRODUCT,
  CLAIM,
  INSURANCE,
  COUNTRY,
  REPORTED_DAMAGE,
  PURCHASE_SUBSCRIPTIONS,
  PURCHASE_SINGLE_DEBTOR_TEST,
}
@Injectable()
export class ExportService {
  constructor(
    private toastService: ToastService,
    private userControllerService: UsersControllerService,
    private resellerControllerService: ResellersControllerService,
    private operatorControllerService: OperatorsControllerService,
    private debtorControllerService: DebtorsControllerService,
    private assessmentControllerService: AssessmentsControllerService,
    private insuranceControllerService: InsurancesControllerService,
    private countriesControllerService: CountriesControllerService,
    private reportDamagesControllerService: ReportDamagesControllerService,
    private purchaseControllerService: PurchasesControllerService,
  ) {}

  public inProgress = false;
  private exportType: CurrentExportType = CurrentExportType.NONE;
  private filters: FilterValues = {};
  public showExportButton = new Subject<boolean>();

  public executeExport() {
    this.sendExportRequest();
  }

  public setFilters(filters: FilterValues) {
    this.filters = filters;
  }

  public setExportType(type: CurrentExportType) {
    this.exportType = type;
  }

  public setExport(state: boolean) {
    this.showExportButton.next(state);
  }

  private showSuccessToastMessage() {
    this.toastService.show({
      title: 'De export is succesvol afgerond',
      description: 'Vergeet niet dat gefilterde waardes worden toegepast op de export.',
      type: ToastType.Info,
    });
  }

  private showErrorToastMessage() {
    this.toastService.show({
      title: 'Er ging iets fout tijdens het exporteren. Probeer opnieuw of neem contact op met ons.',
      type: ToastType.Error,
    });
  }

  private async sendExportRequest() {
    if (this.exportType === CurrentExportType.NONE) return;

    this.inProgress = true;

    // Create the same handling for each type of export
    const serviceSubscription: Partial<Observer<unknown>> = {
      error: () => {
        this.showErrorToastMessage();
        this.inProgress = false;
      },
      next: (response: any) => {
        const blobObj = new Blob([response], {
          type: 'text/csv;charset=utf-8;',
        });
        const blobUrl = URL.createObjectURL(blobObj);
        window.location.replace(blobUrl);
        this.showSuccessToastMessage();
        this.inProgress = false;
      },
    };

    if (!this.filters) {
      this.showErrorToastMessage();
      this.inProgress = false;
      return;
    }

    const filter = {
      filterValues: this.filters
    } as ExportRequest

    switch (this.exportType) {
      case CurrentExportType.MEMBERS:
        this.userControllerService.export(filter).subscribe(serviceSubscription);
        break;
      case CurrentExportType.RESELLERS:
        this.resellerControllerService.export(filter).subscribe(serviceSubscription);
        break;
      case CurrentExportType.OPERATORS:
        this.operatorControllerService.export(filter).subscribe(serviceSubscription);
        break;
      case CurrentExportType.ASSESSMENT:
        this.assessmentControllerService.export(filter).subscribe(serviceSubscription);
        break;
      case CurrentExportType.DEBTOR:
        this.debtorControllerService.export(filter).subscribe(serviceSubscription);
        break;
      case CurrentExportType.INSURANCE:
        this.insuranceControllerService.export(filter).subscribe(serviceSubscription);
        break;
      case CurrentExportType.COUNTRY:
        this.countriesControllerService.export(filter).subscribe(serviceSubscription);
        break;
      case CurrentExportType.REPORTED_DAMAGE:
        this.reportDamagesControllerService.export(filter).subscribe(serviceSubscription);
        break;
      case CurrentExportType.PURCHASE_SUBSCRIPTIONS:
        this.purchaseControllerService.exportSubscriptions(filter).subscribe(serviceSubscription);
        break;
      case CurrentExportType.PURCHASE_SINGLE_DEBTOR_TEST:
        this.purchaseControllerService.exportSingleDebtor(filter).subscribe(serviceSubscription);
        break;
      default:
        break;
    }
  }
}
