import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-environment-logo',
  templateUrl: './environment-logo.component.html',
  styleUrls: ['./environment-logo.component.scss']
})
export class EnvironmentLogoComponent {
  @Input() public customClass: string | undefined;
  @Input() public source: string | undefined;
}
