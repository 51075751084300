import { Injectable } from "@angular/core";
import { ControllerBase, Upload, ModelBase } from "@trade4sure/t4s-lib";
import { Observable } from "rxjs";
import { CreateFileResponse } from "../../models/file/file.model";

@Injectable()
export class FileControllerService extends ControllerBase {
  protected resourceName = 'File';

  public create(file: File): Observable<Upload<CreateFileResponse>> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.postWithProgress<FormData, CreateFileResponse>([], formData);
  }

  public deleteById(id: string): Observable<ModelBase | undefined> {
    return this.delete<ModelBase>([id]);
  }
}