<div class="loader-container">
  <div class="loader">
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M38.853 36.1413C35.8666 39.7003 31.8302 42.2225 27.3223 43.3465C22.8143 44.4705 18.0663 44.1384 13.7587 42.398C9.45101 40.6576 5.80492 37.5982 3.34294 33.6582C0.880967 29.7182 -0.270494 25.1 0.0535909 20.4654C0.377675 15.8307 2.16067 11.4177 5.14702 7.85867C8.13338 4.29967 12.1698 1.77745 16.6777 0.653494C21.1857 -0.470461 25.9337 -0.138446 30.2413 1.60196C34.549 3.34236 38.1951 6.40179 40.6571 10.3418L36.9256 12.6734C34.9561 9.52143 32.0392 7.07388 28.5931 5.68156C25.147 4.28924 21.3485 4.02363 17.7422 4.92279C14.1358 5.82196 10.9067 7.83974 8.51762 10.6869C6.12853 13.5341 4.70214 17.0646 4.44287 20.7723C4.1836 24.48 5.10477 28.1746 7.07435 31.3266C9.04393 34.4786 11.9608 36.9261 15.4069 38.3184C18.853 39.7108 22.6515 39.9764 26.2578 39.0772C29.8642 38.178 33.0933 36.1603 35.4824 33.3131L38.853 36.1413Z"
        [attr.fill]='environmentColor'
      />
    </svg>
  </div>
</div>
