import { Injectable } from '@angular/core';
import { ApiClientService, ModelBase } from '@trade4sure/t4s-lib';
import { DeleteUserRequest } from '../../../models/users/users.model';
import { Observable } from 'rxjs';
import { ResellerRecord, ResellersListRequest, ResellersListResponse, SaveResellerRequest } from '../../../models/users/resellers/resellers.model';
import { QueryControllerBase } from '../../query-controller-base.service';

@Injectable({
  providedIn: 'root'
})
export class ResellersControllerService extends QueryControllerBase {
  protected resourceName = 'ResellerUser';

  constructor(protected override apiClient: ApiClientService) {
    super(apiClient);
  }

  public getResellersList(resellersListRequest: ResellersListRequest): Observable<ResellersListResponse | undefined> {
    return this.post<ResellersListRequest, ResellersListResponse>(['list'], resellersListRequest);
  }

  public getUserDetailsById(id: string): Observable<ResellerRecord | undefined> {
    return this.get<ResellerRecord>([id])
  }

  public activateUserById(id: string): Observable<ModelBase | undefined> {
    return this.apiClient.putWithoutErrorHandling<DeleteUserRequest, ModelBase>(this.resourceName, [id, 'activate']);
  }

  public deleteUserById(id: string, body: DeleteUserRequest): Observable<ModelBase | undefined> {
    return this.apiClient.postWithoutErrorHandling<DeleteUserRequest, ModelBase>(this.resourceName, [id, 'delete'], body);
  }

  public saveReseller(body: SaveResellerRequest): Observable<ModelBase | undefined> {
    return this.post<SaveResellerRequest, ModelBase>([], body);
  }
}
