<div class="header" *ngIf="isVisible">
  <div class="header__inner">
    <div *ngIf="header.title" class="heading">
      <span class="heading__title">{{ header.title }}</span>
      <div class="button-row">
        <button
          *ngIf="header.hasExport"
          class="material-symbols-outlined export"
          (click)="onExportClicked()"
          [class.exporting]="exportService.inProgress"
          [disabled]="exportService.inProgress"
        >
          download
        </button>
        <button *ngIf="header.button" class="add-button btn btn-primary" (click)="onButtonClick(header.button)">
          <span class="add-button__icon material-symbols-outlined">add</span>
          <span class="add-button__text">{{ header.button.title }}</span>
        </button>
      </div>
    </div>
    <app-filter *ngIf="header.filter" [filters]="header.filters"></app-filter>
    <app-breadcrumbs *ngIf="header.breadCrumbs.length" [breadcrumbs]="header.breadCrumbs"></app-breadcrumbs>
    <div *ngIf="header.tabs.length" class="d-flex">
      <t4s-tab-switcher [tabs]="header.tabs" [activeTab]="header.activeTab" (tabChanged)="onTabChange($event)"></t4s-tab-switcher>
    </div>
  </div>
</div>
