import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@node-packages/auth';
import { SubscriptionSolver } from '@trade4sure/t4s-lib';
import { T4SDecodedToken } from 'src/app/modules/api-client/models/token/token.model';
import { BackofficeUser } from 'src/app/modules/api-client/models/user/user.model';
import { BackofficeUserControllerService } from 'src/app/modules/api-client/services/user/backoffice-user/backoffice-user.service';

export interface NavigationItem {
  sideMenuTitle: string;
  route: string;
  hasSeparator?: boolean;
  roles: string[];
}

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent extends SubscriptionSolver {
  public currentUser: BackofficeUser | undefined;

  private navigationItems: NavigationItem[] = [];

  @Input() public logoSrc = '';

  public setNavigationItems() {
    this.route.routeConfig?.children?.forEach((route) => {
      if (route.data && route.path && route.data['sideMenuTitle']) {
        this.navigationItems.push({
          sideMenuTitle: route.data['sideMenuTitle'] ?? '',
          route: route.path,
          hasSeparator: route.data['hasSeparator'],
          roles: route.data['roles'],
        });
      }
    });
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService<T4SDecodedToken>,
    private backofficeUserService: BackofficeUserControllerService,
  ) {
    super();
    this.navigationItems = [];
    this.setNavigationItems();

    this.backofficeUserService.currentUser.subscribe((user) => {
      this.currentUser = user;
    });
  }

  public getNavigationItems(): NavigationItem[] {
    const navigationItems: NavigationItem[] = [];
    if (this.currentUser) {
      for (const navigationItem of this.navigationItems) {
        if (navigationItem.roles !== undefined) {
          const hasRights = navigationItem.roles.includes(this.currentUser.role);
          if (hasRights) navigationItems.push(navigationItem);
        } else {
          navigationItems.push(navigationItem);
        }
      }
      return navigationItems;
    }
    return this.navigationItems;
  }

  public isActivateRoute(route: string): boolean {
    return this.router.url.split('?')[0].endsWith(route);
  }

  public navigationClick(item: NavigationItem): void {
    this.router.navigate([item.route]);
  }

  public logout(): void {
    this.authenticationService.logout();
    this.backofficeUserService.clearCurrentUser();
  }
}
