import { Component, EventEmitter, Input, Output } from '@angular/core';
import { v4 } from 'uuid';
import { AllItem, DropdownItem, Item } from './multi-dropdown.model';

@Component({
  selector: 'app-multi-dropdown',
  templateUrl: './multi-dropdown.component.html',
  styleUrls: ['./multi-dropdown.component.scss'],
})
export class MultiDropdownComponent {
  @Input() set items(items: DropdownItem[]) {
    this.copiedItems = [
      ...items.map((item) => ({
        uuid: v4(),
        label: item.label,
        value: item.value,
        checked: false,
        visible: true,
      })),
    ];
  }
  @Input() placeholder = 'Selecteer items';
  @Input() showSearch = true;
  @Input() showAll = true;
  @Input() showStatus = true;
  @Output() selectedItems: EventEmitter<string[]> = new EventEmitter();

  public allItem: AllItem = {
    label: 'Alles',
    value: null,
    checked: false,
    visible: true,
  };
  public copiedItems: Item[] = [];
  public searchCount = 0;
  public searching = false;

  public set search(searchText: string) {
    this.searching = searchText ? true : false
    this.copiedItems.forEach((x) => {
      x.visible = x.label.toLowerCase().includes(searchText.toLowerCase());
    });
  }

  public get amountOfFoundItems() {
    return this.copiedItems.filter((x) => x.visible).length;
  }

  public get selectedLabels(): string {
    return this.allItem.checked
      ? this.allItem.label
      : this.copiedItems
          .filter((x) => x.checked)
          .map((x) => x.label)
          .join(', ');
  }

  public get filters(): boolean {
    return this.showSearch || this.showAll;
  }

  public get isEmpty(): boolean {
    return !this.copiedItems.filter((x) => x.visible).length;
  }

  public get amountOfItems(): number {
    return this.copiedItems.length;
  }

  public get amountOfCheckedItems(): number {
    return this.copiedItems.filter((x) => x.checked).length;
  }

  public trackByUUID(index: number, item: Item): string {
    return item.uuid;
  }

  public onItemCheckboxChange(event: Event, item: Item): void {
    const checkbox = event.target as HTMLInputElement;
    item.checked = checkbox.checked;
    this.allItem.checked = false;
    this.selectedItems.emit(this.copiedItems.filter((x) => x.checked).map((item) => item.value));
  }

  public onAllCheckboxChange(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    this.allItem.checked = checkbox.checked;
    this.copiedItems.forEach((x) => (x.checked = checkbox.checked));
    this.selectedItems.emit(this.copiedItems.filter((x) => x.checked).map((item) => item.value));
  }

  public stopPropagation(event: MouseEvent) {
    event.stopPropagation();
  }
}
