import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { Trade4SureCoreComponentsModule, Trade4SureGraphicComponentsModule, Trade4SureInputComponentsModule } from '@trade4sure/t4s-lib';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { DatagridComponent } from './datagrid/datagrid.component';
import { FilterComponent } from './filter/filter.component';
import { FileUploadDisplayComponent } from './file-upload-display/file-upload-display.component';
import { LoaderComponent } from './loader/loader.component';
import { EnvironmentLogoComponent } from './environment-logo/environment-logo.component';
import { TooltipComponent } from './tooltip/tooltip.component';

@NgModule({
  declarations: [
    DatagridComponent,
    BreadcrumbsComponent,
    FilterComponent,
    EnvironmentLogoComponent,
    FileUploadDisplayComponent,
    LoaderComponent,
    TooltipComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    Trade4SureCoreComponentsModule,
    Trade4SureInputComponentsModule,
    NgbModule,
  ],
  exports: [
    DatagridComponent,
    BreadcrumbsComponent,
    FilterComponent,
    EnvironmentLogoComponent,
    FileUploadDisplayComponent,
    LoaderComponent,
    Trade4SureGraphicComponentsModule,
    TooltipComponent,
  ],
})
export class GraphicComponentsModule {}
