import { Injectable } from '@angular/core';
import { ApiClientService, ControllerBase, ModelBase } from '@trade4sure/t4s-lib';
import { Observable } from 'rxjs';
import {
  DebtorExternalIdRequest,
  DebtorRecordDetailResponse,
  DebtorsListRequest,
  DebtorsListResponse,
} from '../../models/debtors/debtors.model';
import { QueryControllerBase } from '../query-controller-base.service';

@Injectable({
  providedIn: 'root',
})
export class DebtorsControllerService extends QueryControllerBase {
  protected resourceName = 'Debtor';

  public getDebtorList(debtorsListRequest: DebtorsListRequest): Observable<DebtorsListResponse | undefined> {
    return this.post<DebtorsListRequest, DebtorsListResponse>(['list'], debtorsListRequest);
  }

  public getDebtorDetailsById(id: string): Observable<DebtorRecordDetailResponse | undefined> {
    return this.get<DebtorRecordDetailResponse>([id]);
  }

  public updateDebtorGrade(id: string): Observable<ModelBase | undefined> {
    return this.put([id, 'grade-request']);
  }

  public patchExternalId(
    companyId: number,
    debtorExternalIdRequest: DebtorExternalIdRequest,
  ): Observable<DebtorRecordDetailResponse | undefined> {
    return this.patch(`${companyId}`, debtorExternalIdRequest);
  }
}
