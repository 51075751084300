import { Component, Input } from '@angular/core';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent {
  @Input() title = '';
  @Input() description = '';
  @Input() position: PlacementArray = 'top';
  @Input() style? = '';
  @Input() class? = '';
}
