<div class="dropdown">
  <button
    class="btn btn-outline-primary btn-block text-start dropdown-toggle"
    type="button"
    data-bs-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    data-display="static"
  >
    <span>{{ selectedLabels ? selectedLabels : placeholder }}</span>
  </button>
  <div class="dropdown-menu w-100">
    <div *ngIf="filters" class="filters">
      <div *ngIf="showSearch" class="search-field" (click)="stopPropagation($event)">
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Zoeken" [(ngModel)]="search" />
          <div class="input-group-append">
            <span class="input-group-text h-100">
              <i class="bi bi-search"></i>
            </span>
          </div>
        </div>
      </div>
      <div *ngIf="showAll" class="dropdown-item content-only" (click)="stopPropagation($event)">
        <div class="dropdown-item-field">
          <input
            type="checkbox"
            [id]="'checkbox-' + allItem.label | lowercase"
            (change)="onAllCheckboxChange($event)"
            [checked]="allItem.checked"
          />
          <label [for]="'checkbox-' + allItem.label | lowercase">
            <span [title]="allItem.label">{{ allItem.label }}</span>
          </label>
        </div>
      </div>
    </div>
    <div *ngIf="copiedItems.length" class="dropdown-item-checkboxes">
      <ng-container *ngFor="let item of copiedItems; trackBy: trackByUUID">
        <div class="dropdown-item content-only" (click)="stopPropagation($event)" *ngIf="item.visible">
          <div class="dropdown-item-field">
            <input
              type="checkbox"
              [id]="'checkbox-' + item.label | lowercase"
              (change)="onItemCheckboxChange($event, item)"
              [checked]="item.checked"
            />
            <label [for]="'checkbox-' + item.label | lowercase">
              <span [title]="item.label">{{ item.label }}</span>
            </label>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="dropdown-item content-only" *ngIf="isEmpty">Geen item(s) gevonden</div>
    <div *ngIf="showStatus" class="dropdown-information">
      <span><b>Aantal items:</b> {{ amountOfItems }}</span>
      <span><b>Geselecteerde items:</b> {{ amountOfCheckedItems }}</span>
      <span *ngIf="searching"><b>Gevonden items:</b> {{ amountOfFoundItems }}</span>
    </div>
  </div>
</div>
