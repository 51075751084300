import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { EnvironmentDetailsControllerService, Trade4SureSharedModule } from '@trade4sure/t4s-lib';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GraphicComponentsModule } from './components/graphic/graphic-components.module';
import { CoreSiteComponentsModule } from './components/site/core/core-site-components.module';
import { ApiClientModule } from './modules/api-client/api-client.module';
import { BackofficeUser } from './modules/api-client/models/user/user.model';
import { AuthenticationControllerService } from './modules/api-client/services/authentication/authentication-controller.service';
import { BackofficeUserControllerService } from './modules/api-client/services/user/backoffice-user/backoffice-user.service';
import { HeaderService } from './modules/site/services/header.service';
import { ExportService } from './modules/site/services/export.service';
import { errorResponseMap } from './_data/errorResponseMap';

registerLocaleData(localeNl, 'nl');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    Trade4SureSharedModule.forRoot<AuthenticationControllerService, BackofficeUser, BackofficeUserControllerService>(
      {
        additionalRequestErrorMap: errorResponseMap,
        afterLoginRoute: '/portal',
        loginRoute: '/login',
        apiBaseUrl: environment.apiBaseUrl,
        authRequests: ['/Identity/', '/CustomerUser/create', '/CustomerUser/account/email'],
        showValidationErrors: environment.showValidationErrors,
        cdnStorageUrl: environment.cdnStorageUrl,
      },
      AuthenticationControllerService,
      BackofficeUserControllerService,
    ),
    ApiClientModule,
    CoreSiteComponentsModule,
    GraphicComponentsModule,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'nl',
    },
    HeaderService,
    ExportService,
    EnvironmentDetailsControllerService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
