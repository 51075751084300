import { Pagination, PaginationConfig } from "../components/graphic/datagrid/datagrid.component";

export class ConfigData {
  static noActiveTab = "resetTab";

  static pagination = {
    max: 5,
    initPage: 1,
    pageSize: 25,
  };

  static defaultPagination: Pagination = {
    page: 1,
    pageSize: 25,
  };

  static defaultPaginationConfig: PaginationConfig = {
    totalPages: 0,
    totalSize: 0,
  };
}