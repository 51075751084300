import { Injectable } from '@angular/core';
import { AuthControllerBase, TokensResultModel as AuthTokensResultModel } from '@node-packages/auth';
import { ModelBase } from '@trade4sure/t4s-lib';
import { Observable } from 'rxjs';
import { LoginPostBody } from '../../models/authentication/authentication.model';
import { TokensResultModel } from '../../models/authentication/tokensResult.model';
import { ActivateAccountDTO, SendResetLinkDTO, VerifyResetLinkDTO } from '../../models/user/user.model';
import { ApiClientService } from '../api-client.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationControllerService extends AuthControllerBase {
  private resourceName = 'Identity';
  constructor(private apiClient: ApiClientService) {
    super();
  }
  public login<TokensResultModel>(loginBody: LoginPostBody): Observable<TokensResultModel> {
    return this.apiClient.post<LoginPostBody, TokensResultModel>(
      this.resourceName,
      ['connect', 'authorize'],
      loginBody
    );
  }

  public access(accessToken: string): Observable<AuthTokensResultModel> {
    return this.apiClient.post<object, TokensResultModel>(
      this.resourceName,
      ['connect', 'token', 'access'],
      {},
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      },
    );
  }

  public refresh(refreshToken: string): Observable<AuthTokensResultModel> {
    return this.apiClient.post<object, TokensResultModel>(
      this.resourceName,
      ['connect', 'token', 'refresh'],
      {},
      {
        headers: {
          authorization: `Bearer ${refreshToken}`,
        },
      },
    );
  }

  public activateAccount(activateObject: ActivateAccountDTO): Observable<TokensResultModel> {
    return this.apiClient.post<ActivateAccountDTO, TokensResultModel>(
      this.resourceName, ['activate'], activateObject
    );
  }

  public sendResetLink(resetLinkObject: SendResetLinkDTO): Observable<ModelBase> {
    return this.apiClient.post<SendResetLinkDTO, ModelBase>(
      this.resourceName, ['sendresetlink'], resetLinkObject
    );
  }

  public verifyResetLink(resetLinkObject: VerifyResetLinkDTO): Observable<ModelBase> {
    return this.apiClient.post<VerifyResetLinkDTO, ModelBase>(
      this.resourceName, ['verifyresetlink'], resetLinkObject
    );
  }
}
