import { ErrorSpec } from '@trade4sure/t4s-lib';

export const errorResponseMap: Record<string, Record<string, Array<ErrorSpec>>> = {
  'Data': {
    'GradeNotChangedResponse': [
      {
        message: 'Grade is hetzelfde als de huidige grade'
      }
    ],
    'DebtorNotFoundResponse': [
      {
        message: 'Debiteur niet gevonden'
      }
    ],
    'FailedToRequestGradeResponse': [
      {
        message: 'Het opvragen van de grade is mislukt'
      }
    ],
    'CompanyGradePendingResponse': [
      {
        message: 'De grade is nog in afwachting'
      }
    ],
    'FailedToCreateCompanyResponse': [
      {
        message: 'Het opvragen van de Eurler ID is mislukt'
      }
    ],
    'SubscriptionPurchaseNotActiveSubscription': [
      {
        message: 'Geselecteerde pakket is niet het actieve pakket'
      }
    ],
    'SubscriptionPurchaseExpired': [
      {
        message: 'Geselecteerde pakket is verlopen'
      }
    ],
    'SubscriptionPurchaseNotActiveOrFailed': [
      {
        message: 'Geselecteerde pakket is niet afgerond of mislukt'
      }
    ],
  }
}