import { Injectable } from '@angular/core';
import { ModelBase } from '@trade4sure/t4s-lib';
import { Observable } from 'rxjs';
import { CountryDetailResponse, CountryListRequest, CountryListResponse } from '../../models/country/country.model';
import { QueryControllerBase } from '../query-controller-base.service';

@Injectable()
export class CountriesControllerService extends QueryControllerBase {
  protected resourceName = 'Countries';

  public getCountryList(countryListRequest: CountryListRequest): Observable<CountryListResponse | undefined> {
    return this.post<CountryListRequest, CountryListResponse>(['list'], countryListRequest);
  }

  public getCountryById(id: string): Observable<CountryDetailResponse | undefined> {
    return this.get<CountryDetailResponse>([id]);
  }

  public deleteCountryById(id: string): Observable<ModelBase | undefined> {
    return this.delete([id]);
  }

  public activateCountryById(id: string): Observable<ModelBase | undefined> {
    return this.put([`${id}/activate`]);
  }
}
