import { HttpClient, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModelBase } from '@trade4sure/t4s-lib';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

export type Parameters = HttpParams | Record<string, string>;

export type Header = string | string[];

export type PathSegments = string[] | string;

@Injectable({
  providedIn: 'root',
})
export class ApiClientService {
  constructor(private http: HttpClient) {}

  public get<T extends ModelBase>(
    resource: string,
    pathSegments?: PathSegments,
    requestOptions?: {
      params?: Parameters;
      headers?:
        | HttpHeaders
        | {
            [header: string]: Header;
          }
        | undefined;
      responseType?: 'json';
    },
  ): Observable<T> {
    return this.http
      .get<T>(this.toUrl(resource, pathSegments), {
        ...requestOptions,
      })
      .pipe(
        map((response) => response as T),
        catchError((err) => of(err.error as T)),
      );
  }

  public getFile(
    resource: string,
    pathSegments?: PathSegments,
    requestOptions: {
      params?: Parameters;
      headers?:
        | HttpHeaders
        | {
            [header: string]: Header;
          }
        | undefined;
      responseType: 'blob';
    } = {
      responseType: 'blob',
    },
  ): Observable<Blob> {
    return this.http
      .get(this.toUrl(resource, pathSegments), {
        ...requestOptions,
      })
      .pipe(map((response) => response as Blob));
  }

  public post<R, T = R>(
    resource: string,
    pathSegments?: PathSegments,
    body?: R,
    requestOptions?: {
      params?: Parameters;
      headers?:
        | HttpHeaders
        | {
            [header: string]: Header;
          }
        | undefined;
      responseType?: 'json';
    },
  ): Observable<T> {
    return this.http
      .post<T>(this.toUrl(resource, pathSegments), body, {
        ...requestOptions,
      })
      .pipe(
        map((response) => response as T),
        catchError((err) => of(err.error as T)),
      );
  }

  public postWithProgress<R, T = R>(
    resource: string,
    pathSegments?: PathSegments,
    body?: R,
    requestOptions?: {
      params?: Parameters;
      headers?:
        | HttpHeaders
        | {
            [header: string]: Header;
          }
        | undefined;
      responseType?: 'json';
    },
  ): Observable<HttpEvent<T>> {
    return this.http
      .post<T>(this.toUrl(resource, pathSegments), body, {
        ...requestOptions,
        observe: 'events',
        reportProgress: true,
      })
      .pipe(map((response) => response as HttpEvent<T>));
  }

  public put<R, T = R>(
    resource: string,
    pathSegments?: PathSegments,
    body?: R,
    requestOptions?: {
      params?: Parameters;
      headers?:
        | HttpHeaders
        | {
            [header: string]: Header;
          }
        | undefined;
    },
  ): Observable<T> {
    return this.http
      .put<T>(this.toUrl(resource, pathSegments), body, {
        ...requestOptions,
      })
      .pipe(
        map((response) => response as T),
        catchError((err) => of(err.error as T)),
      );
  }

  public delete<T>(
    resource: string,
    pathSegments?: PathSegments,
    requestOptions?: {
      params?: Parameters;
      headers?:
        | HttpHeaders
        | {
            [header: string]: Header;
          }
        | undefined;
      responseType?: 'json';
    },
  ): Observable<T> {
    return this.http
      .delete<T>(this.toUrl(resource, pathSegments), {
        ...requestOptions,
      })
      .pipe(
        map((response) => response as T),
        catchError((err) => of(err.error as T)),
      );
  }

  private toUrl(resource: string, pathSegments?: PathSegments): string {
    const baseUrl = environment.apiBaseUrl;

    return baseUrl + '/' + [resource, pathSegments].flatMap((x) => x).join('/');
  }
}
