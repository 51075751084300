import { Component, Input, OnChanges, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SearchInputComponent } from '@trade4sure/t4s-lib';
import { FilterItemModel } from 'src/app/_models/filter-item.model';
import { KeyValue } from 'src/app/_models/key-value.model';
import { ComponentTypeValues } from 'src/app/_models/_enum/component-type.enum';
import { FilterService } from './services/filter.service';

export interface CustomFormControlData {
  control: FormControl;
  type: string;
  keyFilter: string;
}
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnChanges {
  @ViewChildren(SearchInputComponent) searchInputComponents: QueryList<SearchInputComponent>;

  @Input() filters: FilterItemModel[] = [];

  controls: CustomFormControlData[] = [];

  componentTypeValues = ComponentTypeValues;

  emptyOption = 'Empty';
  loading = false;

  showAllFilters = false;
  initiallyVisibleFilters = 4;
  public showExportButton = true;

  constructor(private filterService: FilterService) {
    this.searchInputComponents = new QueryList<SearchInputComponent>();
  }

  ngOnChanges(): void {
    this._initControls();
  }

  hndSearchClick() {
    const searchInputFilters: KeyValue[] = this._getSearchInputFilters();
    const controlFilters: KeyValue[] = this._getControlFilters();

    // Filter controls based on the "showAllFilters" flag
    const filteredControls = this.controls.filter((_, index) => this.showAllFilters || index < this.initiallyVisibleFilters);

    // Combine filters from visible controls
    const filters: KeyValue[] = [
      ...searchInputFilters,
      ...controlFilters,
      ...filteredControls.map(({ keyFilter, control }) => ({
        key: keyFilter,
        value: control.getRawValue() === this.emptyOption ? null : control.getRawValue(),
      })),
    ];

    this.filterService.loading$.next(true);
    this.filterService.filters$.next(filters);
  }

  hndClearClick() {
    this.searchInputComponents.forEach((searchInputComponent) => {
      if (searchInputComponent) {
        searchInputComponent.control.setValue(null);
      }
    });

    this.controls.forEach((controlData) => {
      controlData.control.setValue(null);
    });

    this.filterService.loading$.next(true);
    this.filterService.filters$.next([]);
  }

  hndHideAllFilterClick() {
    this.showAllFilters = !this.showAllFilters;
  }

  hndResetDatePickerValue(index: number) {
    this.controls[index].control.setValue(null);
  }

  private _initControls() {
    this.controls = this.filters.map((item) => {
      const control = new FormControl();
      const controlData: CustomFormControlData = {
        control: control,
        type: item.type,
        keyFilter: item.keyFilter,
      };
      return controlData;
    });
  }

  private _getSearchInputFilters(): KeyValue[] {
    return this.searchInputComponents
      .filter((searchInputComponent) => searchInputComponent && searchInputComponent.control.value)
      .map(({ label, control }) => ({
        key: this._getLabelKey(label),
        value: control.getRawValue(),
      }));
  }

  private _getControlFilters(): KeyValue[] {
    return this.controls
      .filter((control) => control.type === ComponentTypeValues.Select || control.type === ComponentTypeValues.DatePicker)
      .map(({ keyFilter, control }) => ({
        key: keyFilter,
        value: control.getRawValue() === this.emptyOption ? null : control.getRawValue(),
      }));
  }
  private _getLabelKey(label: string): string {
    const key = this.filters.find((filter) => filter.placeholder === label)?.keyFilter;

    return key ?? '';
  }
}
