import { Injectable } from '@angular/core';
import { ModelBase } from '@trade4sure/t4s-lib';
import { Observable } from 'rxjs';
import { DeleteUserRequest } from '../../../models/users/users.model';
import { SaveOperatorRequest, OperatorRecord, OperatorsListRequest, OperatorsListResponse } from '../../../models/users/operators/operators.model';
import { QueryControllerBase } from '../../query-controller-base.service';

@Injectable({
  providedIn: 'root',
})
export class OperatorsControllerService extends QueryControllerBase {
  protected resourceName = 'AdminUser';

  public getOperatorsList(operatorsListRequest: OperatorsListRequest): Observable<OperatorsListResponse | undefined> {
    return this.post<OperatorsListRequest, OperatorsListResponse>(['list'], operatorsListRequest);
  }

  public getUserDetailsById(id: string): Observable<OperatorRecord | undefined> {
    return this.get<OperatorRecord>([id]);
  }

  public activateUserById(id: string): Observable<ModelBase | undefined> {
    return this.apiClient.putWithoutErrorHandling<DeleteUserRequest, ModelBase>(this.resourceName, [id, 'activate']);
  }
  
  public deleteUserById(id: string, body: DeleteUserRequest): Observable<ModelBase | undefined> {
    return this.apiClient.postWithoutErrorHandling<DeleteUserRequest, ModelBase>(this.resourceName, [id, 'delete'], body);
  }

  public saveOperator(body: SaveOperatorRequest): Observable<ModelBase | undefined> {
    return this.post<SaveOperatorRequest, ModelBase>([], body);
  }

}
