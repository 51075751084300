import { Injectable } from '@angular/core';
import { ApiClientService, ControllerBase } from '@trade4sure/t4s-lib';
import { Observable } from 'rxjs';
import {
  CountryValuesResponse,
  CustomerUserValuesResponse,
  DebtorValuesResponse,
  InsuranceClaimValuesResponse,
  PurchaseValuesResponse,
  SingleDebtorTestProductValuesResponse,
  SubscriptionProductValuesResponse,
} from '../../models/static-values/static-values.model';

@Injectable()
export class StaticValuesControllerService extends ControllerBase {
  protected resourceName = 'StaticValues';

  constructor(protected override apiClient: ApiClientService) {
    super(apiClient);
  }

  public getCustomerUserValues(): Observable<CustomerUserValuesResponse | undefined> {
    return this.get<CustomerUserValuesResponse>(['CustomerUser']);
  }

  public getCountryValues(): Observable<CountryValuesResponse | undefined> {
    return this.get<CountryValuesResponse>(['Countries']);
  }

  public getInsuranceClaimValues(): Observable<InsuranceClaimValuesResponse | undefined> {
    return this.get<InsuranceClaimValuesResponse>(['InsuranceClaim']);
  }

  public getSubscriptionProductValues(): Observable<SubscriptionProductValuesResponse | undefined> {
    return this.get<SubscriptionProductValuesResponse>(['SubscriptionProducts']);
  }

  public getSingleDebtorTestProductValues(): Observable<SingleDebtorTestProductValuesResponse | undefined> {
    return this.get<SingleDebtorTestProductValuesResponse>(['SingleDebtorTestProduct']);
  }

  public getDebtorValues(): Observable<DebtorValuesResponse | undefined> {
    return this.get<DebtorValuesResponse>(['Debtor']);
  }

  public getPurchasesValues(): Observable<PurchaseValuesResponse | undefined> {
    return this.get<PurchaseValuesResponse>(['Purchase']);
  }
}
