<div class="menu-container d-flex flex-column justify-content-between">
  <span>
    <img [src]="logoSrc" class="w-65 mb-4"/>
    <div class="title-container" *ngFor="let navigationItem of getNavigationItems()">
      <div class="menu-button" [routerLink]="navigationItem.route"
        [ngClass]="{ active: isActivateRoute(navigationItem.route) }">
        <span class="d-flex" >
          {{ navigationItem.sideMenuTitle }}
        </span>
      </div>
      <div class="separator" *ngIf="navigationItem.hasSeparator">
      </div>
    </div>
  </span>
  <span>
    <hr class="my-4" />
    <div class="d-flex mb-2 justify-content-between align-items-center">
      <a routerLink="/portal/profile" class="d-flex flex-grow-1">
        <t4s-profile-image [user]="currentUser"></t4s-profile-image>
        <div class="d-flex flex-column mx-2 flex-grow-1">
          <span class="name">{{ currentUser?.fullName ?? 'Naam' }}</span>
          <span class="email">{{ currentUser?.email ?? 'Email' }}</span>
        </div>
      </a>
      <span (click)="logout()" class="material-symbols-outlined logout ms-auto"> logout </span>
    </div>
  </span>
</div>