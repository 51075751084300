import { Injectable } from '@angular/core';
import { ApiClientService, UserControllerBase } from '@trade4sure/t4s-lib';
import { ReplaySubject, map, take } from 'rxjs';
import { BackofficeUser } from '../../../models/user/user.model';

@Injectable({
  providedIn: 'root',
})
export class BackofficeUserControllerService extends UserControllerBase<BackofficeUser> {
  protected resourceName = 'CurrentUser';

  constructor(protected override apiClient: ApiClientService) {
    super(apiClient);

    this.currentUser = this._currentUser.asObservable();
  }

  public fetchCurrentUser(): void {
    this.get<BackofficeUser>().subscribe((user) => {
      this._currentUser.next(user);
    });
  }

  public clearCurrentUser(): void {
    this._currentUser.complete();
    this._currentUser = new ReplaySubject<BackofficeUser | undefined>(1);
    this.currentUser = this._currentUser.asObservable();
  }

  public getUserRole(): string {
    let userRole = "";

    this.currentUser
      .pipe(
        take(1),
        map((x) => {
          if (x) userRole = x?.role;
        }),
      )
      .subscribe();

    return userRole;
  }
}
