import { Component, OnInit } from '@angular/core';
import { EnvironmentDetailsControllerService } from '@trade4sure/t4s-lib';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  public environmentColor = '';
  constructor(private environmentDetailsService: EnvironmentDetailsControllerService) {}
  ngOnInit(): void {
    this.environmentDetailsService.currentEnvironment.subscribe((x) => {
      if(x === undefined) this.environmentColor = '#1F72E2';
      else this.environmentColor = x.colorMap['--primary-500'];
    });
  }
}
