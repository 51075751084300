import { Injectable } from '@angular/core';
import { ApiClientService, ControllerBase, ModelBase } from '@trade4sure/t4s-lib';
import { Observable } from 'rxjs';
import {
  PurchaseSubscriptionListRequest,
  PurchaseSubscriptionResponse,
  SingleDebtorListRequest,
  SingleDebtorResponse,
} from '../../models/purchases/purchase-subscription.model';
import { ExportRequest } from '../query-controller-base.service';

type ChangeOrderStatus = {
  PurchaseId: string;
  MarkAsPaid: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class PurchasesControllerService extends ControllerBase {
  protected override resourceName = 'Purchase';

  constructor(protected override apiClient: ApiClientService) {
    super(apiClient);
  }

  public getPurchaseSubscriptionList(
    purchaseSubscriptionListRequest: PurchaseSubscriptionListRequest,
  ): Observable<PurchaseSubscriptionResponse | undefined> {
    return this.post<PurchaseSubscriptionListRequest, PurchaseSubscriptionResponse>(
      ['Subscriptions', 'list'],
      purchaseSubscriptionListRequest,
    );
  }

  public markSubscriptionAsPaid(purchaseId: string): Observable<ModelBase | undefined> {
    return this.put<ChangeOrderStatus, ModelBase>(['Subscriptions', 'status'], { PurchaseId: purchaseId, MarkAsPaid: true });
  }

  public markSubscriptionAsUnpaid(purchaseId: string): Observable<ModelBase | undefined> {
    return this.put<ChangeOrderStatus, ModelBase>(['Subscriptions', 'status'], { PurchaseId: purchaseId, MarkAsPaid: false });
  }

  public getSingleDebtorList(singleDebtorListRequest: SingleDebtorListRequest): Observable<SingleDebtorResponse | undefined> {
    return this.post<SingleDebtorListRequest, SingleDebtorResponse>(['SingleDebtorTest', 'list'], singleDebtorListRequest);
  }

  public exportSubscriptions(exportRequest: ExportRequest): Observable<string | undefined> {
    return this.postWithoutPipe<ExportRequest>(['Subscriptions', 'export'], exportRequest);
  }

  public exportSingleDebtor(exportRequest: ExportRequest): Observable<string | undefined> {
    return this.postWithoutPipe<ExportRequest>(['SingleDebtorTest', 'export'], exportRequest);
  }
}
